import React, { useEffect, useState } from 'react';
import { createRoot } from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ZakekeEnvironment, ZakekeProvider, BootParameters } from '@zakeke/zakeke-configurator-react';

const zakekeEnvironment = new ZakekeEnvironment();

const queryString = window.location.search;
const urlParams = new URLSearchParams(queryString);
const allParameters = Array.from(urlParams.entries());

// Convert allParameters [key, value] to object
const queryParameters = Object.fromEntries(allParameters) as BootParameters;

// Add dam viewer styles
if (urlParams.get('dam') === 'true') {
	document.body.classList.add('dam-viewer');
}

// Test
//(window as any).Zakeke.config.baseApiUrl = 'https://localhost:44365/api/';

function parseJwt(token: string) {
	var base64Url = token.split('.')[1];
	var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
	var jsonPayload = decodeURIComponent(
		window
			.atob(base64)
			.split('')
			.map(function (c) {
				return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
			})
			.join('')
	);

	return JSON.parse(jsonPayload);
}

const Viewer = () => {
	const [parameters, setParameters] = useState<BootParameters | null>(null);

	useEffect(() => {
		if (parameters) return;

		if (!queryParameters || Object.keys(queryParameters).length === 0) {
			throw new Error('No parameters found');
		}

		let userId = urlParams.get('userID');
		if (!userId) {
			// Convert queryParameters.token base65 to string
			const decodedToken = parseJwt(queryParameters.token!);
			userId = decodedToken.UserID;
		}

		fetch((window as any).Zakeke.config.baseApiUrl + 'user/visitor_token?userID=' + userId)
			.then((response) => response.text())
			.then((data: string) => {
				console.log(data);
				queryParameters.token = data;
				setParameters(queryParameters);
			});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (!parameters || Object.keys(parameters).length === 0) {
		return null;
	}

	return (
		<ZakekeProvider environment={zakekeEnvironment} parameters={parameters}>
			<App />
		</ZakekeProvider>
	);
};

// Error boundary
class ErrorBoundary extends React.Component<{ children: React.ReactNode }, { hasError: boolean; error: string }> {
	state = { hasError: false, error: '' };

	static getDerivedStateFromError() {
		console.log('Error');
		return { hasError: true };
	}

	componentDidCatch(error: any, errorInfo: any) {
		// You can also log the error to an error reporting service
		this.setState({ error: error.toString() });
		console.log(error, errorInfo);
	}

	render() {
		if (this.state.hasError) {
			return <div>{this.state.error || 'Something went wrong.'}</div>;
		}

		return this.props.children;
	}
}

const rootElement = document.getElementById('root');
if (rootElement) {
	const root = createRoot(rootElement);
	root.render(
		<React.StrictMode>
			<ErrorBoundary>
				<Viewer />
			</ErrorBoundary>
		</React.StrictMode>
	);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
